const COLOR_VARIABLES = {
  primary: 'hsl(213,46%, 25%)',
  'background-white': 'hsl(60, 9%,98%)',
  'background-blue-15': 'hsl(230, 80%, 72%, .15)',
  'background-yellow-10': 'hsl(55,100%, 50%, .1)',
  'accent-brown': 'hsl(23, 55%, 32%)',
  'accent-yellow': ' hsl(55, 100%, 50%)',
  'text-charcoal': 'hsl(214, 17%, 30%)',
  'text-charcoal-60': 'hsl(214, 17%, 30%, 0.6)',
  'text-electric-blue': 'hsl(208, 16%, 40%, 1)',
  error: 'hsl(0, 65%, 51%)',
  white: '#FFFFFF'
}

const SHADOW_VARIABLES = {
  white: {
    'white-low': `0.3px 0.5px 0.7px hsl(60deg 3% 61% / 0.34),
    0.4px 0.8px 1px -1.2px hsl(60deg 3% 61% / 0.34),
    1px 2px 2.5px -2.5px hsl(60deg 3% 61% / 0.34)`,
    'white-medium': `0.3px 0.5px 0.7px hsl(60deg 3% 61% / 0.36),
    0.8px 1.6px 2px -0.8px hsl(60deg 3% 61% / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(60deg 3% 61% / 0.36),
    5px 10px 12.6px -2.5px hsl(60deg 3% 61% / 0.36)`,
    'white-high': `0.3px 0.5px 0.7px hsl(60deg 3% 61% / 0.34),
    1.5px 2.9px 3.7px -0.4px hsl(60deg 3% 61% / 0.34),
    2.7px 5.4px 6.8px -0.7px hsl(60deg 3% 61% / 0.34),
    4.5px 8.9px 11.2px -1.1px hsl(60deg 3% 61% / 0.34),
    7.1px 14.3px 18px -1.4px hsl(60deg 3% 61% / 0.34),
    11.2px 22.3px 28.1px -1.8px hsl(60deg 3% 61% / 0.34),
    17px 33.9px 42.7px -2.1px hsl(60deg 3% 61% / 0.34),
    25px 50px 62.9px -2.5px hsl(60deg 3% 61% / 0.34)`
  },
  yellow10: {
    'yellow-low': `0.3px 0.5px 0.7px hsl(56deg 27% 58%/ 0.34),
    0.4px 0.8px 1px -1.2px hsl(56deg 27% 58%/ 0.34),
    1px 2px 2.5px -2.5px hsl(56deg 27% 58%/ 0.34)`,
    'yellow-medium': `  0.3px 0.5px 0.7px hsl(56deg 27% 58%/ 0.36),
    0.8px 1.6px 2px -0.8px hsl(56deg 27% 58%/ 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(56deg 27% 58%/ 0.36),
    5px 10px 12.6px -2.5px hsl(56deg 27% 58%/ 0.36)`,
    'yellow-high': `  0.3px 0.5px 0.7px hsl(56deg 27% 58%/ 0.38),
    1.7px 3.3px 4.2px -0.4px hsl(56deg 27% 58%/ 0.38),
    3.2px 6.5px 8.2px -0.8px hsl(56deg 27% 58%/ 0.38),
    5.7px 11.3px 14.2px -1.2px hsl(56deg 27% 58%/ 0.38),
    9.6px 19.3px 24.3px -1.7px hsl(56deg 27% 58%/ 0.38),
    15.9px 31.7px 39.9px -2.1px hsl(56deg 27% 58%/ 0.38),
    25px 50px 62.9px -2.5px hsl(56deg 27% 58%/ 0.38)`
  },
  blue15: {
    'blue-low': `0.3px 0.5px 0.7px hsl(226deg 19% 58% / 0.34),
    0.4px 0.8px 1px -1.2px hsl(226deg 19% 58% / 0.34),
    1px 2px 2.5px -2.5px hsl(226deg 19% 58% / 0.34)`,
    'blue-medium': `0.3px 0.5px 0.7px hsl(226deg 19% 58% / 0.36),
    0.8px 1.6px 2px -0.8px hsl(226deg 19% 58% / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(226deg 19% 58% / 0.36),
    5px 10px 12.6px -2.5px hsl(226deg 19% 58% / 0.36)`,
    'blue-high': `0.3px 0.5px 0.7px hsl(226deg 19% 58% / 0.38),
    1.7px 3.3px 4.2px -0.4px hsl(226deg 19% 58% / 0.38),
    3.2px 6.5px 8.2px -0.8px hsl(226deg 19% 58% / 0.38),
    5.7px 11.3px 14.2px -1.2px hsl(226deg 19% 58% / 0.38),
    9.6px 19.3px 24.3px -1.7px hsl(226deg 19% 58% / 0.38),
    15.9px 31.7px 39.9px -2.1px hsl(226deg 19% 58% / 0.38),
    25px 50px 62.9px -2.5px hsl(226deg 19% 58% / 0.38)`
  }
}

const FONT_SIZE_VARIABLES = {
  xs: '1rem',
  sm: '1.3rem',
  base: '1.6rem',
  md: '2rem',
  lg: '2.5rem',
  xl: '3.1rem',
  '2xl': '3.9rem',
  '3xl': '4.9rem',
  '4xl': '6.1rem'
}

const FONT_WEIGHT_VARIABLES = {
  normal: 400,
  semibold: 600,
  bold: 700,
  black: 900
}

const SPACING_VARIABLES = {
  unit: '4px',
  '1': 'var(--spacing-unit)',
  '2': `calc(2*var(--spacing-unit))`,
  '3': `calc(3*var(--spacing-unit))`,
  '4': `calc(4*var(--spacing-unit))`,
  '5': `calc(5*var(--spacing-unit))`,
  '6': `calc(6*var(--spacing-unit))`,
  '7': `calc(7*var(--spacing-unit))`,
  '8': `calc(8*var(--spacing-unit))`,
  '9': `calc(9*var(--spacing-unit))`,
  '10': `calc(10*var(--spacing-unit))`,
  '11': `calc(11*var(--spacing-unit))`,
  '12': `calc(12*var(--spacing-unit))`,
  '13': `calc(13*var(--spacing-unit))`,
  '14': `calc(14*var(--spacing-unit))`,
  '15': `calc(15*var(--spacing-unit))`,
  '16': `calc(16*var(--spacing-unit))`,
  '17': `calc(17*var(--spacing-unit))`,
  '18': `calc(18*var(--spacing-unit))`,
  '19': `calc(19*var(--spacing-unit))`,
  '20': `calc(20*var(--spacing-unit))`,
  '21': `calc(21*var(--spacing-unit))`,
  '22': `calc(22*var(--spacing-unit))`,
  '23': `calc(23*var(--spacing-unit))`,
  '24': `calc(24*var(--spacing-unit))`,
  '25': `calc(25*var(--spacing-unit))`,
  '26': `calc(26*var(--spacing-unit))`,
  '27': `calc(27*var(--spacing-unit))`,
  '28': `calc(28*var(--spacing-unit))`,
  '29': `calc(29*var(--spacing-unit))`,
  '30': `calc(30*var(--spacing-unit))`,
  '31': `calc(31*var(--spacing-unit))`,
  '32': `calc(32*var(--spacing-unit))`,
  '33': `calc(33*var(--spacing-unit))`,
  '34': `calc(34*var(--spacing-unit))`,
  '35': `calc(35*var(--spacing-unit))`,
  '36': `calc(36*var(--spacing-unit))`,
  '37': `calc(37*var(--spacing-unit))`,
  '38': `calc(38*var(--spacing-unit))`,
  '39': `calc(39*var(--spacing-unit))`,
  '40': `calc(40*var(--spacing-unit))`,
  '41': `calc(41*var(--spacing-unit))`,
  '42': `calc(42*var(--spacing-unit))`,
  '43': `calc(43*var(--spacing-unit))`,
  '44': `calc(44*var(--spacing-unit))`,
  '45': `calc(45*var(--spacing-unit))`,
  '46': `calc(46*var(--spacing-unit))`,
  '47': `calc(47*var(--spacing-unit))`,
  '48': `calc(48*var(--spacing-unit))`,
  '49': `calc(49*var(--spacing-unit))`,
  '50': `calc(50*var(--spacing-unit))`,
  '51': `calc(51*var(--spacing-unit))`,
  '52': `calc(52*var(--spacing-unit))`,
  '53': `calc(53*var(--spacing-unit))`,
  '54': `calc(54*var(--spacing-unit))`,
  '55': `calc(55*var(--spacing-unit))`,
  '56': `calc(56*var(--spacing-unit))`,
  '57': `calc(57*var(--spacing-unit))`,
  '58': `calc(58*var(--spacing-unit))`,
  '59': `calc(59*var(--spacing-unit))`,
  '60': `calc(60*var(--spacing-unit))`
}

const PADDING_VARIABLES = {
  tb: {
    'tb-sm': '60px',
    'tb-md': '100px'
  }
}

const cvar = (name: keyof typeof COLOR_VARIABLES) => `var(--color-${name})`
const fsvar = (name: keyof typeof FONT_SIZE_VARIABLES) => `var(--text-${name})`
const fwvar = (name: keyof typeof FONT_WEIGHT_VARIABLES) =>
  `var(--font-${name})`
const svar = (name: keyof typeof SPACING_VARIABLES) => `var(--spacing-${name})`

const shadowvar = (
  name:
    | keyof typeof SHADOW_VARIABLES.white
    | keyof typeof SHADOW_VARIABLES.yellow10
    | keyof typeof SHADOW_VARIABLES.blue15
) => `var(--shadow-${name})`

const pvar = (name: keyof typeof PADDING_VARIABLES.tb) =>
  `var(--padding-${name})`

export {
  cvar,
  pvar,
  fsvar,
  fwvar,
  svar,
  shadowvar,
  PADDING_VARIABLES,
  COLOR_VARIABLES,
  FONT_SIZE_VARIABLES,
  FONT_WEIGHT_VARIABLES,
  SPACING_VARIABLES,
  SHADOW_VARIABLES
}
