import { HiMenuAlt4 } from 'react-icons/hi'
import { MdClose } from 'react-icons/md'
import styled, { css, StyledProps } from 'styled-components'
import { cvar, fsvar, mediaQueries, svar } from 'styles'

export const containerStyles = () => css`
  ${Wrapper} & {
    --justify-content: space-between;
    --align-items: center;
    --flex-direction: row;
    --padding-top: ${svar('2')};
    --padding-bottom: ${svar('2')};
  }
`
export const CloseButton = styled(MdClose)<StyledProps<{ open: boolean }>>`
  --display: none;

  display: var(--display);
  color: red;
  z-index: 200;
  position: absolute;
  top: 15px;
  right: 22px;

  font-size: ${fsvar('md')};
  ${({ open }) =>
    open &&
    css`
      --display: block;
    `};
`

export const MenuButton = styled(HiMenuAlt4)<StyledProps<{ open: boolean }>>`
  color: ${cvar('background-white')};
  ${({ open }) =>
    open &&
    css`
      display: none;
    `};
`

export const Wrapper = styled.header`
  background-color: ${cvar('primary')};
  min-height: ${svar('12')};
  z-index: 100;

  ${mediaQueries.tabletUp} {
    display: none;
  }
`
