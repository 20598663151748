import styled, { css } from 'styled-components'
import { cvar, mediaQueries } from 'styles'

export const Wrapper = styled.header`
  z-index: 1;
  --background-color: ${cvar('primary')};
  position: relative;
  isolation: isolate;
  display: none;
  background-color: var(--background-color);
  min-height: var(--min-height);

  ${mediaQueries.tabletUp} {
    display: block;
  }

  ${mediaQueries.tabletUp} {
    --background-color: transparent;
  }
`

export const BackgroundWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  ${mediaQueries.desktopUp} {
    display: block;
  }
`

export const containerStyles = () => css`
  ${Wrapper} & {
    --padding-top: 0;
    --padding-bottom: 0;
  }
`
