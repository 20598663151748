import { Link as GatsbyLink } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import { CSSPropertiesWithVars, ILinkData } from 'types'

export interface IMyLinkProps {
  link: ILinkData
  className?: string
  activeClass?: string
  onClick?: () => void
  style?: CSSPropertiesWithVars
}
export default function MyLink({
  link,
  className,
  onClick,
  activeClass,
  style
}: IMyLinkProps) {
  const myLink = link.isAnchor ? (
    <AnchorLink
      onAnchorLinkClick={onClick}
      to={link.to}
      title={link.text}
      className={className}
      gatsbyLinkProps={{ activeClassName: activeClass, style }}
      stripHash
    />
  ) : (
    <GatsbyLink
      onClick={onClick}
      to={link.to}
      className={className}
      activeClassName={activeClass}
      style={style}
    >
      {link.text}
    </GatsbyLink>
  )

  if (link.isExternal) {
    return (
      <a
        className={className}
        href={link.to}
        target="_blank"
        rel="noopener noreferrer"
        style={style}
      >
        {link.text}
      </a>
    )
  }
  return myLink
}
