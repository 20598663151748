import { Wrapper as FooterWrapper } from 'components/Footer/styles'
import { Wrapper as HeaderWrapper } from 'components/MainHeader/styles'
import { Wrapper as MobileHeaderWrapper } from 'components/MobileHeader/styles'
import MyLink from 'components/MyLink'
import links from 'data/links'
import React from 'react'
import styled, { css, StyledProps } from 'styled-components'
import { cvar, fsvar, mediaQueries, svar } from 'styles'

interface INavProps {
  isMobile?: boolean
  isOpen?: boolean
  onClick?: () => void
  darkLinks?: boolean
}

export default function Nav({
  isMobile,
  isOpen,
  onClick,
  darkLinks
}: INavProps) {
  const filteredLinks = isMobile
    ? links
    : links.filter(link => link.isMobile !== true)

  return (
    <Wrapper isOpen={isOpen} darkLinks={darkLinks}>
      <Links>
        {filteredLinks.map(link => (
          <ListItem darkLinks={darkLinks} key={link.to}>
            <MyLink onClick={onClick} link={link} />
          </ListItem>
        ))}
      </Links>
    </Wrapper>
  )
}

const Wrapper = styled.nav<
  StyledProps<{ isOpen?: boolean; darkLinks?: boolean }>
>`
  color: ${props =>
    props.darkLinks ? cvar('primary') : cvar('background-white')};
  text-transform: capitalize;

  ${MobileHeaderWrapper} & {
    position: fixed;
    height: 100%;
    background: ${cvar('primary')};
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: ${fsvar('md')};
    transition: transform 300ms;
  }

  ${({ isOpen }) =>
    isOpen === false &&
    css`
      transform: translateX(-100%);
    `};
`

const Links = styled.ul`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('5')};

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);

  ${HeaderWrapper} & {
    --flex-direction: row;
    --gap: ${svar('10')};
  }

  ${FooterWrapper} & {
    --flex-direction: column;
    --gap: ${svar('2')};

    ${mediaQueries.desktopUp} {
      --flex-direction: row;
      --gap: ${svar('10')};
    }
  }
`

const ListItem = styled.li<StyledProps<{ darkLinks?: boolean }>>`
  ${mediaQueries.tabletUp} {
    &:after {
      --after-width: 0;
      content: '';
      display: block;
      background: ${props =>
        props.darkLinks ? cvar('primary') : cvar('accent-yellow')};
      height: 2px;
      width: var(--after-width);
      bottom: -2px;
      border-radius: 100px;
      transition: 0.3s;
    }

    &:hover:after {
      --after-width: 100%;
    }
  }
`
