import { WrapPageElementBrowserArgs } from 'gatsby'
import MainLayout from 'layouts/MainLayout'
import React from 'react'
import GlobalStyles from 'styles/GlobalStyles'

export default function RootWrapper({
  element,
  props
}: WrapPageElementBrowserArgs) {
  const { pageContext } = props
  return (
    <MainLayout layout={pageContext.layout}>
      <GlobalStyles />
      {element}
    </MainLayout>
  )
}
