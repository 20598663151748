import React, { ComponentPropsWithoutRef } from 'react'
import styled, {
  FlattenSimpleInterpolation,
  StyledProps
} from 'styled-components'
import { mediaQueries, pvar } from 'styles'

interface IContainerProps extends ComponentPropsWithoutRef<'div'> {
  styles?: () => FlattenSimpleInterpolation
}

export default function Container({ styles, ...rest }: IContainerProps) {
  return <Wrapper styles={styles} {...rest} />
}

const Wrapper = styled.div<StyledProps<IContainerProps>>`
  --display: flex;
  --justify-content: flex-start;
  --align-items: stretch;
  --flex-direction: column;
  --gap: 0;
  --padding-top: ${pvar('tb-sm')};
  --padding-bottom: ${pvar('tb-sm')};
  --padding: var(--padding-container);

  align-items: var(--align-items);
  justify-content: var(--justify-content);
  display: var(--display);
  padding: var(--padding);
  flex-direction: var(--flex-direction);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  gap: var(--gap);
  min-height: inherit;

  ${mediaQueries.desktopUp} {
    --padding-top: ${pvar('tb-md')};
    --padding-bottom: ${pvar('tb-md')};
  }

  ${({ styles }) => styles && styles}
`
