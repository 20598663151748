import { ILinkData } from 'types'

const links: ILinkData[] = [
  { to: '/', text: 'home', isMobile: true },
  { to: '/#services', text: 'services', isAnchor: true },
  { to: '/#experience', text: 'experience', isAnchor: true },
  {
    to: '/#contact',
    text: 'contact',
    isAnchor: true
  },
  { to: '/blog/', text: 'blog' }
]

export default links
