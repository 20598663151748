import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { mediaQueries } from './mediaQueries'
import {
  COLOR_VARIABLES,
  cvar,
  FONT_SIZE_VARIABLES,
  FONT_WEIGHT_VARIABLES,
  fsvar,
  fwvar,
  PADDING_VARIABLES,
  SHADOW_VARIABLES,
  SPACING_VARIABLES,
  svar
} from './variables'

const GlobalStyles = createGlobalStyle`
${reset}

:root {
/* Colors */

${Object.entries(COLOR_VARIABLES)
  .map(([key, value]) => `--color-${key}: ${value};`)
  .join('\n')}


/* Font Size */
${Object.entries(FONT_SIZE_VARIABLES)
  .map(([key, value]) => `--text-${key}: ${value};`)
  .join('\n')}


	/* Font Weight */
	${Object.entries(FONT_WEIGHT_VARIABLES)
    .map(([key, value]) => `--font-${key}: ${value};`)
    .join('\n')}

	/* Shadows */

	${Object.entries(SHADOW_VARIABLES.white)
    .map(([key, value]) => `--shadow-${key}: ${value};`)
    .join('\n')}

	${Object.entries(SHADOW_VARIABLES.yellow10)
    .map(([key, value]) => `--shadow-${key}: ${value};`)
    .join('\n')}

	${Object.entries(SHADOW_VARIABLES.blue15)
    .map(([key, value]) => `--shadow-${key}: ${value};`)
    .join('\n')}

	/* Spacing */
	${Object.entries(SPACING_VARIABLES)
    .map(([key, value]) => `--spacing-${key}: ${value};`)
    .join('\n')}


	/* Padding */
--padding-container: 0 ${svar('6')};
--padding-blog: 0 ${svar('50')};

${Object.entries(PADDING_VARIABLES.tb)
  .map(([key, value]) => `--padding-${key}: ${value};`)
  .join('\n')}



	/* Widths */

	--logo-width: 100px;


	${mediaQueries.tabletUp} {
		--logo-width: 150px;
		--padding-container: 0 ${svar('18')};
	}

	${mediaQueries.tabletLandUp} {
		--padding-container: 0 ${svar('25')};
	}

	${mediaQueries.desktopUp} {
		--padding-container: 0 ${svar('35')};
		--logo-width: 180px;
		--padding-blog: 0 328px;
	}

	${mediaQueries.bigDesktopUp} {
		--logo-width: auto;
		--padding-blog: 0 556px;
	}

}

*,
*::before,
*::after {
	box-sizing: inherit;
}



html {
	box-sizing: border-box;
	font-size: 62.5%;
}

body {
	font-size: ${fsvar('base')};
	font-family: "Source Sans Pro";
	line-height: 1.75;
	background-color: ${cvar('background-white')};
	font-weight: ${fwvar('normal')};
	color: ${cvar('text-charcoal')};
}

h1,h2,h3,h4,h5,h6 {
	line-height: 1.28;
}

h1{
	font-weight: ${fwvar('black')};
}

h2 {
	font-weight: ${fwvar('bold')};
}

h3, h4, h5, h6 {
	font-weight: ${fwvar('semibold')};
}

a {
	text-decoration: none;
	color: inherit;
}

  textarea::placeholder,
  input::placeholder, textarea {
		font-family: "Source Sans Pro";
    color: ${cvar('text-charcoal-60')};
  }




`

export default GlobalStyles
