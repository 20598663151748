import {
  containerStyles as mainHeaderContainerStyles,
  Wrapper as MainHeaderWrapper
} from 'components/MainHeader/styles'
import Navbar from 'components/Navbar'
import Container from 'layouts/Container'
import React from 'react'
import { cvar } from 'styles'

const BlogHeader = React.forwardRef<HTMLElement>((_props, ref) => (
  <MainHeaderWrapper ref={ref}>
    <Container styles={mainHeaderContainerStyles}>
      <Navbar darkLogo socialColor={cvar('primary')} />
    </Container>
  </MainHeaderWrapper>
))

export default BlogHeader
