import styled, { css } from 'styled-components'
import { cvar, fsvar, mediaQueries, svar } from 'styles'

export const containerStyles = () => css`
  ${Wrapper} & {
    --padding-top: ${svar('5')};
    --padding-bottom: ${svar('5')};
    --flex-direction: row;
    --justify-content: space-between;
  }
`

export const Copyright = styled.p`
  color: ${cvar('background-white')};
  font-size: ${fsvar('xs')};
`

export const Navigation = styled.nav`
  --display: flex;
  --flex-direction: column;
  --justify-content: flex-start;
  --align-items: flex-start;
  --gap: ${svar('6')};

  display: var(--display);
  flex-direction: var(--flex-direction);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  min-height: ${svar('25')};
  gap: var(--gap);

  ${mediaQueries.desktopUp} {
    --flex-direction: row;
    --justify-content: space-between;
    min-width: 60%;
  }
`

export const Wrapper = styled.footer`
  background-color: ${cvar('primary')};
`
