import Logo from 'components/Logo'
import Nav from 'components/Nav'
import SocialIcons from 'components/SocialIcons'
import React from 'react'
import styled from 'styled-components'
import { svar } from 'styles'

interface INavBarProps {
  darkLogo?: boolean
  socialColor?: string
}

export default function Navbar({ darkLogo, socialColor }: INavBarProps) {
  return (
    <Wrapper>
      <Logo dark={darkLogo} isLink />
      <Nav darkLinks={darkLogo} />
      <SocialIcons color={socialColor} />
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${svar('25')};
`
