const breakpoints = {
  smPhone: 413,
  xs: 414,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  xxl: 2000
}

const customMinMediaQuery = (minWidth: number) =>
  `@media only screen and (min-width: ${minWidth}px)`

const customMaxMediaQuery = (maxWidth: number) =>
  `@media only screen and (max-width: ${maxWidth}px)`

export const mediaQueries = {
  smPhoneOnly: customMaxMediaQuery(breakpoints.smPhone),
  phoneOnly: customMaxMediaQuery(breakpoints.xs),
  tabletUp: customMinMediaQuery(breakpoints.sm),
  tabletLandUp: customMinMediaQuery(breakpoints.md),
  desktopUp: customMinMediaQuery(breakpoints.lg),
  bigDesktopUp: customMinMediaQuery(breakpoints.xl),
  hugeDesktopUp: customMinMediaQuery(breakpoints.xxl)
}
