import Navbar from 'components/Navbar'
import { StaticImage } from 'gatsby-plugin-image'
import Container from 'layouts/Container'
import React from 'react'
import * as S from './styles'

const MainHeader = React.forwardRef<HTMLElement>((_props, ref) => (
  <>
    <S.Wrapper ref={ref}>
      <Container styles={S.containerStyles}>
        <Navbar />
      </Container>
    </S.Wrapper>
    <S.BackgroundWrapper>
      <StaticImage
        alt="header background image"
        src="../../assets/images/header-background.png"
        loading="eager"
        placeholder="tracedSVG"
      />
    </S.BackgroundWrapper>
  </>
))

export default MainHeader
