import DarkLogoSvg from 'assets/svgs/dark-logo.inline.svg'
import LogoSvg from 'assets/svgs/logo-reg.inline.svg'
import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

interface ILogoProps {
  width?: number | string
  isLink?: boolean
  dark?: boolean
}

export default function Logo({ width, isLink, dark }: ILogoProps) {
  const styles = {
    '--width': width
  }

  if (isLink)
    return (
      <StyledLink to="/">
        {dark ? (
          <DarkStyledLogo style={styles} />
        ) : (
          <StyledLogo styles={styles} />
        )}
      </StyledLink>
    )

  return dark ? (
    <DarkStyledLogo style={styles} />
  ) : (
    <StyledLogo styles={styles} />
  )
}

const StyledLogo = styled(LogoSvg)`
  width: var(--width, var(--logo-width));
`
const DarkStyledLogo = styled(DarkLogoSvg)`
  width: var(--width, var(--logo-width));
`

const StyledLink = styled(Link)`
  line-height: 0;
`
