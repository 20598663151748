import BlogHeader from 'blog-components/BlogHeader'
import Footer from 'components/Footer/index'
import MainHeader from 'components/MainHeader/index'
import MobileHeader from 'components/MobileHeader'
import React from 'react'
import styled, { StyledProps } from 'styled-components'
import { mediaQueries } from 'styles'
import { PageLayout } from 'types'
import useResizeObserver from 'use-resize-observer'

interface IMainLayoutProps {
  children: React.ReactNode
  layout: PageLayout
}

export default function MainLayout({ children, layout }: IMainLayoutProps) {
  const { ref: footerRef, height: footerHeight } =
    useResizeObserver<HTMLElement>({
      box: 'border-box'
    })

  const { ref: headerRef, height: headerHeight } =
    useResizeObserver<HTMLElement>({
      box: 'border-box'
    })

  const { ref: mobileHeaderRef, height: mobileHeaderHeight } =
    useResizeObserver<HTMLElement>({
      box: 'border-box'
    })

  return (
    <Wrapper
      headerHeight={headerHeight}
      footerHeight={footerHeight}
      mobileHeaderHeight={mobileHeaderHeight}
    >
      {layout === 'blog' ? (
        <BlogHeader />
      ) : (
        <>
          <MainHeader ref={headerRef} />
          <MobileHeader ref={mobileHeaderRef} />
        </>
      )}

      <main>{children}</main>
      <Footer ref={footerRef} />
    </Wrapper>
  )
}

interface ILayoutStyleProps {
  headerHeight?: number
  footerHeight?: number
  mobileHeaderHeight?: number
}

const Wrapper = styled.div<StyledProps<ILayoutStyleProps>>`
  --header-height: ${props => props.headerHeight}px;
  --mobile-header-height: ${props => props.mobileHeaderHeight}px;
  --footer-height: ${props => props.footerHeight}px;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  main {
    height: 100%;
    min-height: calc(
      100vh - var(--mobile-header-height) - var(--footer-height)
    );
    flex: 1 0 auto;

    ${mediaQueries.tabletUp} {
      min-height: calc(100vh - var(--header-height) - var(--footer-height));
    }
  }
`
