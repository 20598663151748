import '@fontsource/source-sans-pro'
import '@fontsource/source-sans-pro/200.css'
import '@fontsource/source-sans-pro/600.css'
import '@fontsource/source-sans-pro/700.css'
import '@fontsource/source-sans-pro/900.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RootWrapper from './site/wrappers/RootWrapper'

toast.configure({
  autoClose: 5000
})

export const wrapPageElement = RootWrapper
