import { useSiteMeta } from 'hooks/useSiteMeta'
import React from 'react'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { FaLinkedinIn } from 'react-icons/fa'
import { HiMail } from 'react-icons/hi'
import styled from 'styled-components'
import { cvar, fsvar, mediaQueries, svar } from 'styles'
import { CSSPropertiesWithVars } from 'types'

interface ISocialIconsProps {
  color?: string
  size?: string
}

export default function SocialIcons({ color, size }: ISocialIconsProps) {
  const { social, email } = useSiteMeta()

  const styles: CSSPropertiesWithVars = {
    '--color': color,
    '--size': size
  }

  return (
    <Wrapper style={styles}>
      {social?.twitter?.url && social?.twitter.handle && (
        <SocialLink
          href={social.twitter.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillTwitterCircle />
        </SocialLink>
      )}
      {social?.linkedin?.url && social?.linkedin?.username && (
        <SocialLink
          href={social.linkedin.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </SocialLink>
      )}
      {email && (
        <SocialLink href={`mailto:${email}`}>
          <Mail />
        </SocialLink>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  color: var(--color, ${cvar('background-white')});
  gap: ${svar('6')};

  ${mediaQueries.desktopUp} {
    gap: ${svar('10')};
  }
`

const SocialLink = styled.a`
  --default-size: ${fsvar('md')};
  font-size: var(--size, var(--default-size));

  ${mediaQueries.desktopUp} {
    --default-size: 2.8rem;
  }
`

const Mail = styled(HiMail)`
  font-size: 2.2rem;

  ${mediaQueries.desktopUp} {
    font-size: 3rem;
  }
`
