import Logo from 'components/Logo'
import Nav from 'components/Nav'
import SocialIcons from 'components/SocialIcons'
import Container from 'layouts/Container'
import * as React from 'react'
import * as S from './styles'

const Footer = React.forwardRef<HTMLElement>((_props, ref) => (
  <S.Wrapper ref={ref}>
    <Container styles={S.containerStyles}>
      <S.Navigation>
        <Logo />
        <Nav />
      </S.Navigation>
      <SocialIcons />
    </Container>
    <Container>
      <S.Copyright>
        © Copyright AKZ GROUP LLC. {new Date().getFullYear()}
      </S.Copyright>
    </Container>
  </S.Wrapper>
))

export default Footer
