import Logo from 'components/Logo'
import Nav from 'components/Nav'
import Container from 'layouts/Container'
import React, { useState } from 'react'
import * as S from './styles'

const MobileHeader = React.forwardRef<HTMLElement>((_props, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeNav = () => {
    setIsOpen(false)
    document.body.style.overflow = 'unset'
  }

  const openNav = () => {
    setIsOpen(true)
    if (typeof window !== 'undefined' && window.document) {
      document.body.style.overflow = 'hidden'
    }
  }

  return (
    <S.Wrapper ref={ref}>
      <Container styles={S.containerStyles}>
        <Logo isLink />
        <Nav isMobile isOpen={isOpen} onClick={closeNav} />
        <S.MenuButton open={isOpen} onClick={openNav} />
      </Container>
      <S.CloseButton open={isOpen} onClick={closeNav} />
    </S.Wrapper>
  )
})

export default MobileHeader
