import { graphql, useStaticQuery } from 'gatsby'
import { SiteMetadataHookQuery } from 'types'

export const query = graphql`
  query SiteMetadataHook {
    site {
      siteMetadata {
        email
        address
        social {
          github {
            username
            url
          }
          linkedin {
            url
            username
          }
          twitter {
            handle
            url
          }
        }
        title
        author {
          name
          summary
        }
        description
        image
        siteUrl
        ogLanguage
        siteLanguage
        siteLocale
      }
    }
  }
`

export function useSiteMeta() {
  const data = useStaticQuery(query) as SiteMetadataHookQuery

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  return data.site?.siteMetadata!
}
